<template>
    <div class="pb-10 h-100 ml-2 mb-20">
        <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
            <div class="breadcrumbactive">
                <div>
                    <span class="pointer" @click="goToCompanyList">Company</span>
                    <div>Company Settings</div>
                </div>
            </div>
        </div>
        <div class="pr-2">
            <!-- <div class="mb-3 d-flex align-items-center w-100" style="position: relative;">
                <i class="icon icon-magnify fs-15" style="position: absolute; left: 10px;"></i>
                <input type="text" placeholder="Search Settings" class="search-input-field">
            </div> -->
            <div class="settings-container">
                <div class="d-flex justify-content-between align-items-center basic-info" style="background-color: #00448b;border-radius: 0px 6px 0 0 !important;">
                    <h6 class="subHeaderTitle my-2 text-white pl-5">
                        <span class="pl-2 fs-14 fw-500 profile-text">Company Settings</span>
                    </h6>
                </div>
                <div class="pt-5 pl-7 w-100" style="min-width: 550px;">
                    <div>
                        <div class="pr-10">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="w-100 mt-4">
                                        <multiselect class="diginew-multiselect" :show-labels="false"
                                            name="section_options" :searchable="true" :options="section_options"
                                            v-model="section_selected" placeholder="Select" @select="changeSection">
                                            <template slot="noOptions">
                                                <div class="text-secondary text-center fw-600">List is Empty</div>
                                            </template>
                                            <template slot="noResult">
                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                            </template>
                                        </multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="pt-5" v-if="section_selected == 'Company Information'">
                                <div>
                                    <h1 class="text-secondary fs-14 md-5">Tax ID</h1>
                                    <div v-if="ajax_call_in_progress"
                                        class="d-flex align-items-center justify-content-center">
                                        <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center mt-8"
                                        v-else-if="!ajax_call_in_progress && company_setting_data.length == 0">
                                        <span class="fw-400" style="color: #00448b;">No Tax ID Added!</span>
                                    </div>
                                    <div v-else class="row w-100" style="max-width: 990px;">
                                        <div v-for="(comp_data, index) in company_setting_data" :key="index"
                                            class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                            <div class="d-flex align-items-center w-100">
                                                <el-checkbox class="mb-0" @change="compCheckbox($event, comp_data.id)"
                                                    :name="'tax_id1'"></el-checkbox>
                                                <div class="input-field d-flex align-items-center w-100 ml-4">
                                                    <span :title="comp_data.value" class="pl-3 fs-14 text-data text-truncate">{{comp_data.value}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row w-100">
                                        <div v-if="add_new_taxid_type" class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                            <div class="d-flex align-items-center w-100 position-relative">
                                                <el-checkbox class="mb-0" :name="'industry_type_id1'"></el-checkbox>
                                                <input class="input-field w-100 ml-4"
                                                    style="padding-left: 21px !important;" type="text"
                                                    v-model="new_taxid_type" maxlength="100" placeholder="Enter Tax Id Type" />
                                                <span style="position: absolute;top: 40px;right: 0px;">
                                                    {{100 - (new_taxid_type).length}}/100
                                                </span>
                                                <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                    style="bottom: auto; top: auto;right: -50px;">
                                                    <div class="pointer" @click="removeEntry('taxid_type_option')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg"
                                                            style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="updateEntry('taxid_type_option')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg"
                                                            style="width: 20px;">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-10 mr-8 text-right">
                                        <button @click="addNew('taxid_type_option')"
                                            class="text-secondary fs-14 pointer text-right d-block ml-auto"
                                            style="background-color: transparent; outline: 0 none !important;">+ Add New
                                            Field</button>
                                        <button v-if="company_setting_data.length" @click="deleteInput('taxid_type_option')"
                                            :disabled="tax_id_type_selected.length < 1" class="btn btn-new-danger mt-4">
                                            <i class="icon icon-minus fs-14"></i>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="pt-5" v-if="section_selected == 'Customer or Vendor Listing'" >
                                <div class="mt-2" :class="{'disable-state' :  vendor_details == '' || vendor_details == undefined}">
                                    <span class="text-secondary fs-14 md-5" :class="{'text-css' :  vendor_details == '' || vendor_details == undefined}">Listed as a Vendor in other login</span>
                                    <el-switch active-color="#00448b" inactive-color="#bcb8b8" v-model="vendor_enable" @change="updateCompSetting(vendor_details,'vendor')" class="routers ml-10" >
                                    </el-switch>
                                </div>
                                <div class="mt-7" :class="{'disable-state' :  cust_details == '' || cust_details == undefined}">   
                                    <span class="text-secondary fs-14 md-5" :class="{'text-css' :  cust_details == '' || cust_details == undefined}">Listed as a Customer in other login</span>
                                    <el-switch active-color="#00448b" inactive-color="#bcb8b8" v-model="cust_enable" @change="updateCompSetting(cust_details,'customer')" class="routers ml-5" >
                                    </el-switch>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>
<script>
    import settings from '../mixins/settings'
    import {
        HalfCircleSpinner
    } from 'epic-spinners';
    import {
        SweetModal
    } from 'sweet-modal-vue'
    export default {
        mixins: [settings],
        components: {
            HalfCircleSpinner,
            SweetModal
        },
        data() {
            return {
                warning_msg: '',
                vendor_listing:false,
                success_msg: '',
                add_new_taxid_type: false,
                new_taxid_type: '',
                section_selected: 'Company Information',
                // section_options: ['Industry Type', 'Contact Type', 'Company Information', 'Number of Employees', 'Currency', 'Branch', 'Department', 'Time Zone'],
                section_options: ['Company Information', 'Customer or Vendor Listing'],
                company_setting_data: [],
                ajax_call_in_progress: false,
                tax_id_type_selected: [],
                company_list:'',
                cust_enable:false,
                vendor_enable:false,
                cust_details:'',
                vendor_details:''

            }
        },
        methods: { 
            //change the value from dropdown
            /**@params = val */ 
            changeSection(val){
                this.section_selected = val;
                this.getListCompSetting();
            },
            //Redirect tot he company list view
            goToCompanyList() {
                this.$router.push('/company/view/cmpid/' + this.$route.params.company_id);
            },
            addNew(type) {
                if (type == 'taxid_type_option') {
                    if (this.add_new_taxid_type) {
                        this.warning_msg = 'Please save in-progress tax id type.'
                        this.$refs.warning_modal.open();
                    }
                    this.add_new_taxid_type = true;
                }
            },
            removeEntry(type) {
                if (type == 'taxid_type_option') {
                    this.add_new_taxid_type = false;
                }
            },
            updateEntry(type) {
                if (type == 'taxid_type_option') {
                    this.addNewCompSetting('company_information');
                }
            },
            deleteInput(type) {
                if (type == 'taxid_type_option') {
                    this.deleteCompSetting('company_information');
                }
            },
            compCheckbox(event, id) {
                if (event === true) {
                    this.tax_id_type_selected.push(id);
                } else {
                    let index = this.tax_id_type_selected.indexOf(id);
                    if (index > -1) {
                        this.tax_id_type_selected.splice(index, 1);
                    }
                }
            },
            //Api to get the list of company setting
            async getListCompSetting() {
                this.ajax_call_in_progress = true;
                this.add_new_taxid_type = false;
                this.new_taxid_type = '';
                this.tax_id_type_selected = [];
                //calling the list company settings api based on the section selected
                let params;
                try { 
                    if(this.section_selected == 'Company Information'){
                        params = {
                            type: 'company_information',
                            company_id: this.$route.params.company_id
                        };
                    }
                    else if(this.section_selected == 'Customer or Vendor Listing'){
                        params = {
                            type: 'company_listing',
                            company_id: this.$route.params.company_id
                        };
                    }
                    let response = await this.getListCompanySettings(params);
                    if (response.status_id == 1) {
                        this.company_setting_data = response.response.company_information;
                        //setting the response based on the type of type
                        if(params.type=='company_listing'){
                            this.company_list = response.response.company_listing;
                            if(this.company_list.as_vendor){
                                this.vendor_details = this.company_list.as_vendor;
                                this.vendor_enable = this.company_list.as_vendor.is_enabled;
                            } else {
                                this.vendor_details = '';
                                //this.vendor_enable = false;
                            }
                            if(this.company_list.as_customer){
                                this.cust_details = this.company_list.as_customer;
                                this.cust_enable = this.company_list.as_customer.is_enabled;
                            } else {
                                this.cust_details = '';
                                //this.cust_enable = false;
                            }
                        }
                    }
                    this.ajax_call_in_progress = false;
                } catch (err) {
                    this.ajax_call_in_progress = false;
                }
            },
            //Api call to update the company settings
            async updateCompSetting(val,type) {
                //Based on the type we enable/disable the data
                if(type == 'vendor'){
                    val.is_enabled = this.vendor_enable
                } else {
                    val.is_enabled = this.cust_enable
                }
                //based on the val api is calling to update company settings
                /**@params = val */
                if(val){
                    this.ajax_call_in_progress = true;
                    /**@params = id, @params = is_enabled */
                    try {
                        let params = {
                            id: val.id,
                            is_enabled: val.is_enabled
                        };
                        let response = await this.updateCompanySettings(params);
                        this.success_msg = response.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                        }, 2000);
                        this.ajax_call_in_progress = false;
                    } catch (err) {
                        this.ajax_call_in_progress = false;
                    }
                }
            },
            //Api call to get the new company
            /**@params = type */
            async addNewCompSetting(type) {
                try {
                    /**@params = value, @params = type, @params= company_id */
                    let params = {
                        value: this.new_taxid_type,
                        type: type,
                        company_id: this.$route.params.company_id
                    };
                    let response = await this.addCompanySettings(params);
                    if (response.status_id == 1) {
                        this.success_msg = response.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.getListCompSetting();
                        }, 2000);
                    }
                } catch (err) {
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                }
            },
            //Api call to delete the company settings
            /**@params = type */
            async deleteCompSetting(type) {
                try {
                    /**@params = value, @params = type, @params = company_id */
                    let params = {
                        id: this.tax_id_type_selected,
                        type: type,
                        company_id: this.$route.params.company_id
                    };
                    let response = await this.deleteCompanySettings(params);
                    if (response.status_id == 1) {
                        this.success_msg = response.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.getListCompSetting();
                        }, 2000);
                    }
                } catch (err) {
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                }
            }
        },
        mounted() {
            this.getListCompSetting();
          //  this.updateCompSetting();
        },
    }
</script>
<style scoped>
    .settings-container {
        padding: 0.4px 0.2px 0px 0;
        border-radius: 0px 6px 0 0;
        background-color: #fff;
        min-height: 49em;
        height: 100%;
        display: -webkit-box;
        display: flex;
        flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-box-flex: 1;
        flex: 1 0 auto;
        width: 100%;
        min-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
    }
    .disable-state{
        pointer-events: none;
        cursor: not-allowed;
        color: #ccc
    }
    .text-css{
        color: #ccc !important
    }
    .input-field{
        height: 38px;
    }
</style>